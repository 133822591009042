<template>
    <st-page layout="tab" :showHeader="false">
        <ApplicationRegisterForm
            :is-new="false"
            :record="record"
        ></ApplicationRegisterForm>
    </st-page>
</template>

<script>
import ApplicationRegisterForm from "@/modules/applications/components/ApplicationRegisterForm.vue";
import { mapGetters } from 'vuex';
export default {
    name: "ApplicationRegister",
    components: { ApplicationRegisterForm },
    props: {
    },
    computed: {
        ...mapGetters({
            record: 'internalDocuments/form/record',
        }),
    },
    methods: {
    },
};
</script>
